import React from "react";
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";

import Layout from "./index";
import components from "../designSystem";

function PolicyLayout({ data }) {
  const { mdx } = data;
  return (
    <MDXProvider components={components}>
      <Layout showWaitlist={false}>
        <section className="flex flex-col w-full items-center justify-center">
          <div className="w-full bg-mph-light-gray py-10">
            <h1 className="text-xl sm:text-3xl text-center text-blackfont-bold tracking-wide">
              {mdx.frontmatter.title}
            </h1>
          </div>
          <div className="w-full max-w-6xl text-black py-10 text-lg tracking-wide">
            <p>Last Updated: {mdx.frontmatter.date}</p>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
        </section>
      </Layout>
    </MDXProvider>
  );
}

PolicyLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query ContentQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        date
      }
    }
  }
`;

export default PolicyLayout;
